import React from 'react';

const CourseBox = ({ course }) => {
  const { id, picture, title, courseDescription, modality } = course;

  return (
    <div id={id} className="coursesContainer">
      <img className="cursoImg" src={picture} alt="" />
      <div className="cursoTxtContainer">
        <div className="cursoTitDesc">
          <h2>{title}</h2>
          <p>{courseDescription}</p>
        </div>
        <div className="modalidad">
          <h3>Modalidad:</h3>
          <h4>{modality}</h4>
        </div>
      </div>
    </div>
  );
};

export default CourseBox;
