import React, { Fragment } from 'react';
import Layout from '../components/Layout';
import pic1 from '../assets/images/cursosImage1.jpg';
import backgroundBannerCursos from '../assets/images/backgroundBannerCursos.jpg';
import ushuaia from '../assets/images/ushuaia-agua.jpg';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import CourseBox from '../components/CourseBox';
import { cursos } from '../assets/texts/cursos';
import DrawerButton from '../components/DrawerButton';

export default function Cursos() {
  return (
    <Fragment>
      <Layout>
        <div id="wrapper">
          <Banner
            title="Cursos"
            subtitle="Dinámicos, interactivos y de una excelente calidad"
            backgroundImage={`url("${backgroundBannerCursos}")`}
          />
          <div className="container">
            <div className="ofrecemos">
              <a href="/#" className="image">
                <img src={pic1} alt="" />
              </a>
              <div className="txtCont">
                <h2>Ofrecemos variedad de cursos</h2>
                <p>
                  Nuestro objetivo es asegurarnos de que todos nuestros
                  estudiantes mejoren su inglés y otros idiomas y alcancen sus
                  metas de aprendizaje. Proporcionamos un plan de estudios
                  personalizado y brindamos un apoyo y atención individualizada
                  durante todo el curso para garantizar el progreso rápidamente
                  y logren sus objetivos de aprendizaje.
                </p>
              </div>
            </div>
            <div className="cursosContainer">
              {Object.keys(cursos).map((item, i) => (
                <CourseBox course={cursos[item]} id={i} />
              ))}
            </div>
            <div className="capacitacion">
              <h1>Capacitación para profesores de Inglés:</h1>
              <h2>
                Una vez al año traemos a la ciudad expertos para compartir sus
                conocimientos y actualizarnos en la enseñanza de idiomas.
              </h2>
              <h1>Begin your spanish journey at the end of the world...</h1>
            </div>
            <img className="ushuaiaImg" src={ushuaia} alt="" />
            <div className="foreigners">
              <p>
                Oxford House is an accredited institute that is conveniently
                located in the city center of Ushuaia. We offer a range of
                Spanish Courses varying from beginner courses to advanced
                courses. We have available options for accommodation, including
                homestay options with a local family. We are available for
                assistance to make sure you have a smooth experience in every
                aspect of your stay during your time in Ushuaia. We consider
                ourselves a unique, because of our commitment to using the most
                innovative teaching strategies including touch screen
                televisions to maximize the learning experience. Each student at
                Oxford House is given the upmost individual attention and we are
                committed to insuring progress in language and an immersive
                Argentine experience.
              </p>
            </div>
            <div className="ushuaiaFooter">
              <p>
                Ushuaia is the last stop before Antartica and boasts the
                position of being the southernmost city in the world. Surrounded
                by the snowcapped Andes mountains and the Beagle Channel,
                Ushuaia transmits naturalistic beauty and tranquility. In the
                summertime, there are numerous hikes that give stunning views of
                unique parts of Patagonia, cruises to Antarctica and the
                opportunity to walk with penguins. In the winter, there is the
                chance to Ski by the Sea at Cerro Castor, which has some of the
                best skiing and snowboarding in the Southern Hemisphere.
                Year-round, you can experience the National Park which presents
                breathtaking landscapes and camping locations
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
      <DrawerButton />
    </Fragment>
  );
}
