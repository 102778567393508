import picCurso1 from '../images/curso03.png';
import picCurso2 from '../images/curso04.png';
import picCurso4 from '../images/curso06.png';

export const cursos = {
  kids23: {
    id: 'kids23',
    title: 'Niños 2 - 3 años',
    courseDescription:
      'Todos sabemos que el aprendizaje del inglés es fundamental para poder movernos con soltura por el mundo en el que vivimos y la tendencia es que sea todavía más importante en el futuro. En Oxford House queremos conseguir que nuestros alumnos consigan este reto lo antes que puedan y lo hagan de la forma más correcta posible, entendiendo y haciéndose entender en inglés. En muchas ocasiones nos hemos encontrado con la misma pregunta: ¿No son muy pequeños los niños de dos y tres años para empezar a aprender inglés? ¿No es mejor esperar a que sean más mayores? Nuestra respuesta es que no, no es muy tarde. Es más, es la mejor edad para empezar a aprender otro idioma. El cerebro de los niños de dos y tres años está especialmente preparado para aprender el idioma materno y cualquier otro idioma al que estén expuestos. De hecho, está comprobado que de los dos a los ocho años es la edad en la que el aprendizaje de otro idioma se puede hacer de una forma más natural, rápida y con un acento similar al de un nativo.',
    modality:
      'Los pequeños tienen dos estímulos por semana de una hora por clase. Los días pueden ser lunes y miércoles o martes o jueves por la mañana o tarde.',
    picture: picCurso4,
  },
  kids46: {
    id: 'kids46',
    title: 'Niños 4 - 6 años',
    courseDescription:
      'Los profesores hacen de sus clases sean muy dinámicas, nuestros niños disfrutan de cada clase a través de canciones, cuentos, juegos, y demás.',
    modality:
      'Los niños de estas edades tienen clases dos veces por semana de hora y media por clase. Contamos con horarios flexibles por la mañana o tarde. Los días pueden ser lunes y miércoles o martes y jueves.',
    picture: picCurso4,
  },
  kids712: {
    id: 'kids712',
    title: 'Niños 7 - 12 años',
    courseDescription:
      'Nuestros niños aprenderán a hablar y a leer la lengua inglesa a través de una inmersión en juegos adaptados a su edad y una simulación de situaciones reales. Es importante entender que una lengua es un medio para alcanzar el objetivo, por lo tanto este curso se basa en juegos y situaciones que estimulen el uso de la lengua de manera natural. He aquí por qué nuestros alumnos tienen un doble beneficio: aprenden la lengua de manera fácil y natural sin presión y salen del aula con la sensación del éxito, habiendo resuelto todos los problemas y habiendo conseguido el resultado deseado.',
    modality:
      'Los niños de estas edades tienen clases dos veces por semana de hora y media por clase. Contamos con horarios flexibles por la mañana o tarde. Los días pueden ser lunes y miércoles o martes y jueves. También contamos con un espacio de apoyo y refuerzo en el caso que lo necesiten.',
    picture: picCurso4,
  },
  teens1317: {
    id: 'teens1317',
    title: 'Adolescentes 13 - 17 años',
    courseDescription:
      'Los adolescentes hacen de sus clases espacios de diversión y motivación a través de debates y conversación, juegos y el uso de las pantallas digitales.',
    modality:
      'Nuestros adolescentes tienen clases dos veces por semana de hora y media por clase. Contamos con horarios flexibles por la mañana o tarde. Los días pueden ser lunes y miércoles o martes y jueves. También contamos con un espacio de apoyo y refuerzo en el caso que lo necesiten.',
    picture: picCurso4,
  },
  adults: {
    id: 'adults',
    title: 'Programa Adultos',
    courseDescription:
      'En estos cursos se adquieren gradualmente herramientas de pronunciación, vocabulario y uso de estructuras gramaticales cada vez más complejas. Esto se va a traducir en la habilidad de expresar opiniones y compartir ideas, en forma escrita y oral, en los más diversos contextos. Se toma contacto con el idioma inglés desde el comienzo mediante actividades comunicativas que requieren una participación activa del alumno. El material que se utiliza incluye textos permanentemente actualizados publicados por las principales editoriales, libros de ejercitación, y todas las soluciones tecnológicas para desarrollar la producción y comprensión oral y escrita. Al completar el sexto nivel, Superior, se alcanza un nivel B2 intermedio superior. Al final de esta etapa el alumno está capacitado para poder desenvolverse satisfactoriamente en inglés, en cualquier situación.',
    modality:
      'Los grupos regulares de adultos tiene clases dos veces por semana de hora y media por clase. Los días lunes y miércoles o martes y jueves.',
    picture: picCurso1,
  },
  intensive: {
    id: 'intensive',
    title: 'Cursos Intensivos',
    courseDescription:
      'Es un curso diseñado para quienes deseen el idioma para poder viajar o trabajar con turismo. Desarrollamos más la parte comuni- cativa y auditiva del idioma.',
    modality:
      'En esta modalidad las clases de dictan tres veces por semana de hora y media por clases. Inician en marzo y culminan la segunda semana de julio y en el segundo periodo del año iniciamos en agosto hasta la segunda semana de diciembre.',
    picture: picCurso1,
  },
  crashCourses: {
    id: 'crashCourses',
    title: 'Crash Courses',
    courseDescription:
      'Oxford house ofrece un plan integral de aprendizaje de inglés para adultos con cualquier nivel de conocimientos. El programa, estructurado en 6 niveles, permite al alumno avanzar progresivamente en la capacidad de lograr una comunicación más fluida y precisa. Los alumnos de los cursos intensivos siguen el mismo programa de los regulares pero al cursar seis horas semanales, abarcan dos niveles en un año. Se toma una evaluación al completarse el nivel inicial a mitad de año de carácter orientativo, que no determina la continuidad de alumno en el curso. En estos cursos se adquieren gradualmente herramientas de pronunciación, vocabulario y uso de estructuras gramaticales cada vez más complejas. Esto se va a traducir en la habilidad de expresar opiniones y compartir ideas, en forma escrita y oral, en los más diversos contextos. Se toma contacto con el idioma inglés desde el comienzo mediante actividades comunicativas que requieren una participación activa del alumno. El material que se utiliza incluye textos permanentemente actualizados publicados por las principales editoriales, libros de ejercitación, y todas las soluciones tecnológicas para desarrollar la producción y comprensión oral y escrita. Al completar el sexto nivel, Superior, se alcanza un nivel B2 intermedio superior. Al final de esta etapa el alumno está capacitado para poder desenvolverse satisfactoriamente en inglés, en cualquier situación.',
    modality:
      'Nuestros alumnos podrán hacer dos niveles en un año. Las clases son tres veces por semana de dos horas por clase de duración. Iniciamos en Marzo hasta segunda semana de julio y empezamos el siguiente nivel en agosto y culmina la segunda semana de diciembre.',
    picture: picCurso2,
  },
  conversation: {
    id: 'conversation',
    title: 'Clases de conversación',
    courseDescription:
      'Los alumnos trabajan en mejorar la fluidez en el habla y su pronunciación, al mismo tiempo que enfrentan situaciones de la vida cotidiana interactuando socialmente. El curso de conversación es un excelente complemento de nuestros cursos anuales e intensivos, donde se focaliza especialmente en la habilidad oral, dentro de un ambiente más informal y ameno. Se encuentra disponible para nivel intermedio y avanzado.',
    modality:
      'Contamos con clases de conversación, se dictan dos clases por semana de 1 hora y 15 minutos de duración.',
    picture: picCurso1,
  },
  specific: {
    id: 'specific',
    title: 'Inglés para propósitos específicos',
    courseDescription:
      'Este curso es ideal para aquellas personas que desean mejorar su inglés en un ámbito concreto, como por ejemplo en el ámbito legal, financiero, tecnológico, médico, turístico, aeronáutico, docente y de personal/recursos humanos. Nuestros profesores, altamente cualificados, diseñan lecciones para que se adapten a tus objetivos y necesidades de aprendizaje. Las lecciones se centran en las habilidades de comunicación comerciales y profesionales, la negociación de estilos y técnicas, las técnicas de presentación, el inglés social y las técnicas de redacción, pero lo que es más importante se centran especialmente en el vocabulario y las expresiones necesarias para comunicarte de forma eficaz en el ámbito que elijas. Ofrecemos clases con grupos pequeños y/o lecciones individualizadas.',
    modality:
      'Inglés a medida, se ofrecen clases grupales e individuales. Las clases grupales de dictan dos o tres veces por semana de hora y media por clase. Las clases individuales el estudiante elige la cantidad de horas por semana por mes que desee hacer.',
    picture: picCurso1,
  },
  clil: {
    id: 'clil',
    title: 'English with CLIL',
    courseDescription:
      'Programa para niños de 6 a 12 años. En este programa nuestros niños podrán aprender diferentes materias en inglés como : ciencia, historia, matemática, geografía, etc.',
    modality:
      'Este programa es diario, los niños tiene clases de lunes a viernes de hora y 15 minutos de duración por clase. Grupos por la mañana o tarde.',
    picture: picCurso2,
  },
  enterprise: {
    id: 'enterprise',
    title: 'Inglés para Empresas',
    courseDescription:
      'Nuestros cursos de inglés in-company están dirigidos al personal de todo tipo de empresas, pequeñas, medianas y grandes, así como a ejecutivos y empresarios, y combinan una metodología participativa y con énfasis en conversación permitiendo incorporar el idioma en el corto plazo y garantizando un rápido y efectivo dominio del idioma. Todos nuestros cursos de inglés para empresas están dirigidos a desarrollar la fluidez oral desde el comienzo. Nuestros alumnos logran mantener conversaciones exitosas al poco tiempo de comenzado el curso así como desenvolverse eficazmente en llamadas telefónicas, reuniones sociales y laborales, viajes al exterior, presentaciones y redactar mails de forma precisa y con un amplio vocabulario.',
    modality:
      'En esta modalidad se coordina con la empresa la carga horaria y días de estudio por semana en el tiempo que lo decida la institución. Se hará un test previo de nivel de diagnóstico para luego agruparlos de acuerdo a los niveles, que podrán ser básico intermedio o avanzado.',
    picture: picCurso2,
  },
  otherLanguages: {
    id: 'otherLanguages',
    title: 'Otros idiomas',
    courseDescription:
      'Francés, italiano, portugués, alemán y español para extranjeros.',
    modality:
      'Dos modalidades: Regular anual : Dos veces por semana de hora y media por clase. Intensivos: Duración de 4 meses y medio , clases tres veces por semana de hora y media por clase.',
    picture: picCurso1,
  },
  oneToOne: {
    id: 'oneToOne',
    title: 'ENGLISH ONE TO ONE',
    courseDescription:
      'Se trata de nuestro curso individual de mayor intensidad individual. No es una inmersión como tal, pero contiene elementos comunes. De carácter combinado, atiende no solo a las necesidades formales del aprendizaje del idioma, sino también a la adquisición de vocabulario y expresiones del idioma coloquial. El objetivo es que el alumno avance de manera significativa en la adquisición de habilidades lingüísticas a través de una formación intensiva y adaptada por completo a sus necesidades.',
    modality:
      'El estudiante decide la cantidad de horas que desee hacer por semana o por mes en horarios coordinados con la administración. Nos enfocamos en las necesidades de cada estudiante.',
    picture: picCurso1,
  },
};
